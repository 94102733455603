const getContent = (result, caseId) => {
  Office.context.mailbox.displayNewMessageForm({
    // toRecipients: Office.context.mailbox.item.to,
    // bccRecipients: [`${caseId}@bcc.casepeer.com`],
    toRecipients: [`${caseId}@bcc.casepeer.com`],
    subject: Office.context.mailbox.item.subject,
    htmlBody: result.value,
    attachments: []
  });
};

const getTextContent = (result, caseId) => {
  Office.context.mailbox.displayNewMessageForm({
    toRecipients: [`${caseId}@bcc.casepeer.com`],
    subject: Office.context.mailbox.item.subject,
    htmlBody: result.value.replace(/\n/g, "<br />"),
    attachments: []
  });
};


const getContentNewEmail = (result, caseId) => {
  Office.context.mailbox.displayNewMessageForm({
    bccRecipients: [`${caseId}@bcc.casepeer.com`],
    // subject: Office.context.mailbox.item.subject,
    htmlBody: "",
    attachments: []
  });
};

export const displayNewEmailComposer = caseId => {
  Office.context.mailbox.item.body.getAsync(
    Office.CoercionType.Html,
    { asyncContext: "This is passed to the callback" },
    result => getContentNewEmail(result, caseId)
  );
};

export const displayMessageComposer = caseId => {
  Office.context.mailbox.item.body.getAsync(
    Office.CoercionType.Html,
    { asyncContext: "This is passed to the callback" },
    html => {if (html.value != '') {
        getContent(html, caseId)
    } else {
        Office.context.mailbox.item.body.getAsync(
          Office.CoercionType.Text,
          { asyncContext: "This is passed to the callback" },
          text => getTextContent(text, caseId)
        );
    }}
  );
};

export const copyText = (text, element) => {
  var original, selection, range;
  original = element.innerText;
  element.innerText = text;
  selection = window.getSelection();
  range = document.createRange();
  range.selectNodeContents(element);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
  element.innerText = 'Copied!';
  setTimeout(()=>{element.innerText = original}, 5000);
};

export const onOpenCase = url => {
  Office.context.ui.displayDialogAsync(url);
};

export const formatDate = doi => {
  let date = new Date(doi);
  var day = date.getDate();
  var month = date.getMonth();
  var year = date.getFullYear();
  //return month + "-" + day + "-" + year;
  return doi;
};
